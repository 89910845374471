<template>
<div>
    <div class="flex justify-center">
        <img class="w-24" :src="require('@/assets/core/logo-sayls.png')" alt="Chiesi Sayl´s" />
    </div>

    <div class="text-center text-xs text-gray-500 mt-6 space-y-1">
        <p>Derechos reservados.</p>
        <p>Material exclusivo para el profesional de la salud.</p>
        <p>Aviso No. 213300C2021850</p>
    </div>

    <div class="text-center text-xs text-gray-400 mt-8 space-x-6 underline">
        <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones</a>
        <a href="/aviso-de-privacidad" target="_blank">Aviso de privacidad</a>
    </div>
</div>
</template>