export default {
	get(response) {
		// AUTH
		if (response.code == 'auth/email-already-in-use')							return 'El correo ya se encuentra en uso.';
		if (response.code == 'auth/invalid-email')									return 'Correo no válido.';
		if (response.code == 'auth/operation-not-allowed')							return 'Operación no permitida.';
		if (response.code == 'auth/weak-password')									return 'Contraseña muy débil.';
		if (response.code == 'auth/user-not-found')									return 'El usuario no existe.';
		if (response.code == 'auth/user-disabled')									return 'El usuario se encuentra deshabilitado.';
		if (response.code == 'auth/wrong-password')									return 'Contraseña incorrecta.';
		if (response.code == 'auth/cancelled-popup-request')						return 'Operación cancelada por apertura de otra ventana.';
		if (response.code == 'auth/popup-blocked')									return 'Ventana de conexión bloqueada por el navegador.';
		if (response.code == 'auth/popup-closed-by-user')							return 'Ventana de conexión cerrada por el usuario.';
		if (response.code == 'auth/expired-action-code')							return 'El código para el cambio de contraseña ha expirado.';
		if (response.code == 'auth/invalid-action-code')							return 'Código inválido para cambio de contraseña.';
		if (response.code == 'auth/duplicate-from-auth')							return 'Ya existe un usuario con esa cuenta de correo.';
		if (response.code == 'auth/account-exists-with-different-credential')		return 'La cuenta ya existe con otro medio de autenticación.';
		if (response.code == 'auth/network-request-failed')							return 'Error de conexión a internet.';
		if (response.code == 'permission-denied')									return 'Operación no permitida.';

		return response.code;
	}

}
