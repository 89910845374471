<template>
	<div class="fixed">
		<img :src="require('@/assets/core/logo.png')" alt="Chiesi México" class="w-48 sm:w-56">
	</div>

	<div class="min-h-screen bg-white flex">
		<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			<div class="mx-auto w-full max-w-sm lg:w-96">
				<div>
					<!-- <img class="h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> -->
					<h2 class="mt-16 sm:mt-6 text-3xl font-extrabold text-accent">
						Renovación de contraseña
					</h2>
					<p class="mt-2 text-sm text-gray-600">
						Te enviaremos un correo para que puedas generar una contraseña nueva.
					</p>
				</div>

				<div class="mt-8">
					<div class="mt-6">
						<form @submit.prevent="submitForm" class="space-y-6">
							<div>
								<label for="email" class="block text-sm font-medium text-gray-700">
									Correo electrónico
								</label>
								<div class="mt-1">
									<input v-model="state.email" id="email" name="email" type="email" autocomplete="email" :disabled="state.sending" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
								</div>
							</div>

							<div>
								<button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent" :disabled="state.sending">
									Renovar
								</button>
							</div>
						</form>
					</div>

					<router-link to="/signin" class="flex items-center text-sm text-primary justify-center mt-8">
						Regresar a inicio
					</router-link>

					<FooterPublic class="mt-8" />
				</div>
			</div>
		</div>
		<div class="hidden lg:block relative w-0 flex-1">
			<img class="absolute inset-0 h-full w-full object-cover" :src="require('@/assets/core/general.png')" alt="" />
		</div>
	</div>
</template>

<script>
import FooterPublic from '@/components/core/FooterPublic'
import FireErrors from '@/services/fireErrors'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Sign from '@/firebase/sign'

export default {
	components: {
		FooterPublic
	},
	setup() {
		const router = useRouter()
		const store = useStore()

		const state = reactive({
			email: '',
			sending: false
		})

		function submitForm() {
			if (state.email == '' || state.email == null) {
				return
			}

			state.sending = true

			Sign.recoverPassword(state.email)
					.then(response => {
						if (response === true) {
							store.dispatch('setNotification', {type: true, content: 'Se ha enviado un correo a tu cuenta.'});
							router.push('/signin')
						} else {
							state.sending = false;
                            window.scrollTo(0, 0);
    						store.dispatch('setNotification', {type: false, content: FireErrors.get(response)});
						}
					})
		}

		return {
			submitForm,
			state
		}
	}
}
</script>



